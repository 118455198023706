<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form ref="form" :model="formData" label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：每天价格设置</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :sm="2" :md="1" style="margin-right: 20px">
                                <el-button style=" margin-top: 8px; margin-right: 120px" type="primary" @click="addClass" size="mini">添加分类
                                </el-button>
                            </el-col>
                            <el-col :sm="2" :md="2" style="margin-right: 5px">
                                <vxe-toolbar import custom export ref="xToolbar2">
                                </vxe-toolbar>
                            </el-col>
                            <el-col :sm="12" :md="5">
                                <el-form-item label="供应商:" label-width="60px">
                                    <el-select ref="supplier"
                                               v-model="formData.supplier"
                                               default-first-option
                                               remote
                                               multiple
                                               collapse-tags
                                               style="width: 100%"
                                               @change="searchEveryList"
                                               :remote-method="$_searchSupplierList" clearable filterable size="mini"
                                               placeholder="可搜索查询"
                                               @keyup.native.enter="$_blurNext('supplier'),$_focusNext('handled_by')">
                                        <el-option
                                                v-for="item in $store.getters.getSupplierList"
                                                :key="item.id"
                                                :label="item.supplier_name"
                                                :value="item.company_uuid">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="4">
                                <el-form-item label="限价日期:">
                                    <el-date-picker
                                            v-model="pickerDate"
                                            @change="changeTimeHour"
                                            align="right"
                                            size="mini"
                                            style="width:150px"
                                            type="datetime"
                                            format="yyyy-MM-dd HH:mm:ss"
                                            placeholder="选择日期"
                                            :clearable="false"
                                            :picker-options="pickerOptions">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="3">
                                <el-form-item label="多时段调价:" label-width="90px">
                                    <el-select size="mini" v-model="timeHour" @change="changePickerDate" placeholder="请选择">
                                        <el-option
                                                v-for="item in timeHourList"
                                                :key="item.day_time"
                                                :label="item.day_time"
                                                :value="item.day_time">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="8">
                               <!-- <el-button style=" margin-top: 8px; " type="primary" @click="searchEveryList"
                                           size="mini">增加当天多时段调价
                                </el-button>-->
                                <el-button style="margin-top: 8px; " type="success" @click="searchEveryList" size="mini">查询
                                </el-button>
                                <el-button type="primary" size="mini" @click="showGoodsClassDialogFormEvent">设置分类
                                </el-button>
                               <!-- <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini"
                                           @click="synGoodsPrice">同步限价
                                </el-button>-->
                                <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="success" plain size="mini"
                                           @click="setPriceDialogFormVisible = true,goodsClassCheckedEvent()">设置每天价格
                                </el-button>
                                <!--<el-button style=" margin-top: 8px;margin-bottom: 8px; " type="success" plain size="mini"
                                           @click="setPriceDialogFormVisible = true,isCostPrice = true,goodsClassCheckedEvent()">设置成本价
                                </el-button>-->
                                <el-button style=" margin-top: 8px; " type="success" @click="addFormVisible = true" size="mini">新增规格
                                </el-button>
                                <el-button style=" margin-top: 8px; " type="success" @click="showAddBatchNumber = true" size="mini">新增批号
                                </el-button>
                                <el-button style=" margin-top: 8px;margin-left: 5px;" type="danger" @click="deleteEveryDay" size="mini">刪除
                                </el-button>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row>
                    <!--暂时没必要用动画，动画只是一个测试 <transition name='fade'>-->
                    <el-col :sm="12" :md="6" v-if="showGridClass">
                        <el-row>
                            <vxe-grid ref="gridClass"
                                      v-bind="gridOptions"
                                      :checkbox-config="{range:true}"
                                      @checkbox-range-change="searchEveryList"
                                      @cell-click="cellCheckboxRowEvent"
                                      @checkbox-change="searchEveryList"
                                      size='mini'
                                      :height="(this.getViewHeight() - 160)+''"
                                      @checkbox-all="searchEveryList">
                                <template #operateTree="{row}">
                                    <vxe-button icon="el-icon-edit-outline" title="修改" circle
                                                @click="editRowEvent(row)"></vxe-button>
                                    <vxe-button icon="el-icon-delete" title="删除" circle
                                                @click="removeRowEvent(row)"></vxe-button>
                                </template>
                            </vxe-grid>
                        </el-row>
                    </el-col>
                    <!--</transition>-->
                    <vxe-button @click="showGridClass = !showGridClass" class="grid-show-button" status="success"><i :class="showGridClass ? 'el-icon-caret-left':'el-icon-caret-right'"></i></vxe-button>
                    <el-col :sm="12" :md="showGridClass ? 18:24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                ref="xGrid"
                                @checkbox-range-change="goodsClassCheckedEvent"
                                @cell-click="cellCheckboxRowEventGrid"
                                :height="(this.getViewHeight() - 160)+''"
                                :import-config="{
                                        remote: true,
                                        importMethod: this.importMethod,
                                        modes: ['insert'],
                                        type: ['xlsx'],
                                        types: ['xlsx']
                                }"
                                rowId="id"
                                size='medium'
                                highlight-hover-row
                                :export-config="{
                                    filename:'每天价格设置导入',
                                    type: ['xlsx'], types: ['xlsx'],
                                    columns:[
                                        {field:'id'},
                                        {field:'state_stock'},
                                        {field:'every_day_stock_specification'},
                                        {field:'every_day_stock_category'},
                                        {field:'full_thick'},
                                        {field:'polish_roll'},
                                        {field:'company_name'},
                                        {field:'limited_price'},
                                        {field:'cost_price'},
                                        {field:'category_class'},
                                        {field:'limited_price_date'},
                                        ]
                                }"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :mouse-config="{selected: false}"
                                :checkbox-config="{range:true}"
                                :edit-config="{showIcon:true,trigger: 'click', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">
                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,5000,10000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>
                    </el-col>
                </el-row>
            </el-form>
        </el-main>
        <vxe-modal v-model="showAddBatchNumber" :lock-view="false" :mask="false" show-zoom resize
                   margin-size="-500" width="700" height="440" title="批量添加批号">
            <template #default>
                <el-row>
                    <el-col :sm="24" :md="24">
                        <el-tag type="danger" style="margin-bottom: 5px">填写模板：批号(回车)，每一行一个</el-tag>
                        <vxe-textarea v-model="addBatchNumber" style="height: 300px"
                                      :placeholder="'PH2022-12-1350\nPH2023-12-1350\nPH2024-12-1350\nPH2025-12-1350'"
                                      resize="none">
                        </vxe-textarea>
                        <el-button type="warning" @click="addBatchNumberEvent" round
                                   size="mini">确定
                        </el-button>
                    </el-col>
                </el-row>
            </template>
        </vxe-modal>
        <vxe-modal width="420px" :title="showEdit ? '编辑分类' : '新增分类'" v-model="dialogFormVisible" :esc-closable="true" :lock-view="false" :mask="false" resize>
            <el-form ref="specificationsClassificationForm" :model="specificationsClassificationForm"
                     :rules="specificationsClassificationFormRules">
                <el-form-item label="分类名称:" prop="name">
                    <el-input size="mini" v-model="specificationsClassificationForm.name"
                              :disabled="!specificationsClassificationForm.is_able_change" clearable autocomplete="off"
                              style="width: 52%;"></el-input>
                </el-form-item>
                <!--<el-form-item label="分类类别:" prop="roles" label-width="82px">
                    <el-cascader
                            :disabled="!specificationsClassificationForm.is_able_change"
                            size="mini" v-model="specificationsClassificationForm.father_id"
                            :options="gridOptions.data"
                            :props="{ checkStrictly: true ,value:'id',label:'name'}"
                            clearable></el-cascader>
                </el-form-item>-->
            </el-form>
            <div align="center" style="margin-top: 10px">
                <el-button size="mini" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="saveSpecificationsClassification('specificationsClassificationForm')">
                    确 定
                </el-button>
            </div>
        </vxe-modal>
        <vxe-modal width="420px" title="商品分类设置" v-model="goodsClassDialogFormVisible" :esc-closable="true" :lock-view="false" :mask="false" resize>
            <el-form :model="goodsClassForm">
                <el-alert
                        :title="'当前选择 '+checkedGoodsIds.length+' 个商品！'"
                        type="success"
                        :closable="false">
                </el-alert>
                <el-form-item label="分类类别:" prop="roles" label-width="82px">
                    <el-cascader
                            filterable
                            size="mini" v-model="goodsClassForm.classId"
                            :options="gridOptions.data"
                            :props="{ checkStrictly: true ,value:'id',label:'name'}"
                            clearable></el-cascader>
                </el-form-item>
            </el-form>
            <div align="center" style="margin-top: 10px">
                <el-button size="mini" @click="goodsClassDialogFormVisible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="saveGoodsClass('setClass')">确 定</el-button>
            </div>
        </vxe-modal>
        <vxe-modal width="420px" title="设置每天价格" v-model="setPriceDialogFormVisible" :esc-closable="true" :lock-view="false" :mask="false" resize>
            <el-form :model="goodsClassForm">
                <el-alert
                        :title="'当前选择 '+checkedGoodsIds.length+' 个商品！'"
                        type="success"
                        :closable="false">
                </el-alert>
                <el-radio-group v-model="goodsClassForm.setPriceRadio" style=" padding-top: 15px;">
                    <el-radio :label="1">统一设价</el-radio>
                    <el-radio :label="2">基数增加</el-radio>
                    <el-radio :label="3">基数减少</el-radio>
                </el-radio-group>
                <el-form-item label="限价:" label-width="140px">
                    <el-input-number v-model="goodsClassForm.numPrice" size="mini" :controls="false" :precision="2"></el-input-number>
                </el-form-item>
                <el-radio-group v-model="goodsClassForm.setCostRadio" style=" padding-top: 15px;">
                    <el-radio :label="1">统一设价</el-radio>
                    <el-radio :label="2">基数增加</el-radio>
                    <el-radio :label="3">基数减少</el-radio>
                </el-radio-group>
                <el-form-item label="成本价:" label-width="140px">
                    <el-input-number v-model="goodsClassForm.numCost" size="mini" :controls="false" :precision="2"></el-input-number>
                </el-form-item>
            </el-form>
            <el-alert
                    :title="'1、输入值0，则不进行修改。\n2、新增时间限价，输入值0，会自动同步上一次价格。'"
                    type="error"
                    :closable="false">
            </el-alert>
            <div align="center" style="margin-top: 10px">
                <el-button size="mini" @click="setPriceDialogFormVisible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="saveGoodsClass('setPrice')">确 定</el-button>
            </div>
        </vxe-modal>
        <vxe-modal width="420px" title="增加限价规格" v-model="addFormVisible" :esc-closable="true" :lock-view="false" :mask="false" resize>
            <el-form ref="addFrom" :model="addFrom" :rules="addFormRules" style=" text-align: center; ">
                <el-form-item label="供应商" prop="company_uuid">
                    <el-select ref="companyUuidAdd"
                               v-model="addFrom.company_uuid"
                               default-first-option
                               remote
                               style="width: 260px"
                               :remote-method="$_searchSupplierList"
                               clearable
                               filterable
                               size="mini"
                               placeholder="可搜索查询"
                               @keyup.native.enter="$_blurNext('companyUuidAdd'),$_focusNext('categoryAdd')">
                        <el-option
                                v-for="item in $store.getters.getSupplierList"
                                :key="item.id"
                                :label="item.supplier_name"
                                :value="item.company_uuid">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="类别名称" prop="category">
                    <el-select ref="categoryAdd"
                               v-model="addFrom.category"
                               size="mini"
                               no-data-text="没有搜索结果!"
                               placeholder="可搜索查询"
                               default-first-option
                               @clear="$_searchCategoryList"
                               style="width: 250px"
                               clearable
                               filterable
                               @keyup.native.enter="$_blurNext('categoryAdd'),$_focusNext('specificationAdd')">
                        <el-option v-for="item in $store.getters.getCategoryListDefault" :key="item.id" :label="item.name"
                                   :value="item.name">
                            <span style="margin:15px;">{{ item.name}}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="规格" prop="specification" label-width="50px">

                    <el-select ref="specificationAdd"
                               v-model="addFrom.specification"
                               size="mini"
                               no-data-text="没有搜索结果!"
                               placeholder="全部"
                               default-first-option
                               filterable
                               clearable
                               style="width: 260px"
                               >
                        <el-option v-for="item in this.$store.getters.getSpecificationList" :key="item.id" :label="item.specification_name" :value="item.specification_name">
                            <span style="margin:15px;">{{ item.specification_name}}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="足厚" prop="full_thick">
                    <el-select ref="full_thick"
                               v-model="addFrom.full_thick"
                               size="mini"
                               no-data-text="没有搜索结果!"
                               placeholder="可搜索查询"
                               default-first-option
                               :filter-method="$_fullThickListFilter"
                               style="width: 250px"
                               clearable
                               filterable
                               @keyup.native.enter="$_blurNext('full_thick'),$_focusNext('specificationAdd')">
                        <el-option v-for="item in $store.getters.getFullThickList" :key="item.id" :label="item.name" :value="item.name">
                            <span style="margin:15px;">{{item.name}}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="打磨驳口" prop="polish_roll">
                    <el-autocomplete
                            v-model="addFrom.polish_roll"
                            style="width: 260px"
                            size="mini"
                            :fetch-suggestions="$_polishRollQuerySearch"
                            clearable>
                    </el-autocomplete>
                </el-form-item>
            </el-form>
            <div align="center" style="margin-top: 10px">
                <el-button size="mini" @click="addFormVisible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="addGood">确 定</el-button>
            </div>
        </vxe-modal>
        <vxe-modal width="1220px" :title="!isShowDialog ? '失败' : '导入数据'" v-model="dialogImprotVisible"
                   :esc-closable="true" :lock-view="false" :mask="false" resize>
            <el-tag type="danger" style="margin-bottom: 5px">只会更新：“最低限价”、“成本价格”</el-tag>
            <vxe-grid
                    border
                    resizable
                    height="400"
                    show-overflow
                    ref="xGridImport"
                    :columns="tableImport"
                    :data="tableDataImport"
                    :cell-class-name="cellClassName"
                    :edit-config="{trigger: 'click', mode: 'cell', icon: 'fa fa-pencil-square-o'}">
            </vxe-grid>
            <div align="center" style="margin-top: 10px">
                <template v-if="isShowDialog">
                    <el-button @click="dialogImprotVisible = false">取 消</el-button>
                </template>
                <template v-else>
                    <el-button @click="dialogImprotVisible = false">导入失败</el-button>
                </template>
                <el-button type="primary" @click="saveImport" v-show="isShowDialog">确 定</el-button>
            </div>
        </vxe-modal>
    </div>

</template>

<script>
    import XEUtils from 'xe-utils'

    import {filterRender} from "@/utils/gird-filter";

    export default {

        data() {
            return {
                uuid: '',
                isShowDialog: '',
                dialogImprotVisible:false,
                showGridClass:true,
                //分类
                dialogFormVisible: false,
                goodsClassDialogFormVisible: false,
                setPriceDialogFormVisible: false,
                addFormVisible: false,
                showEdit: false,
                showAddBatchNumber: false,
                addBatchNumber: '',
                tableDataImport: [],
                tableImport: [
                    {
                        field:'every_day_stock_id', width: 50, title: 'id'
                    },
                    {
                        field:'category', width: 50, title: '类别'
                    },
                    {
                        field:'specification', width: 150, title: '规格名称'
                    },{
                        field:'full_thick', width: 100, title: '足厚'
                    },{
                        field:'polish_roll', width: 100, title: '打磨驳口'
                    },{
                        field:'company_name', width: 150, title: '供应商/批号'
                    },
                    {
                        field:'limited_price', width: 100, title: '最低限价'
                    },
                    {
                        field:'cost_price', width: 100, title: '成本价格'
                    },
                    {
                        field:'create_date', width: 170, title: '限价日期'
                    },
                    {
                        field:'error', title: '系统提示', width: 200, slots: {
                            default: ({row}) => {
                                return [
                                    <el-link type="danger">{row.error}</el-link>
                            ]
                            },
                        }
                    }
                ],
                specificationsClassificationForm: {
                    id: '',
                    name: '',
                    is_able_change: '',
                    father_id : '',
               },
                addFrom:{
                    company_uuid:'',
                    category:'',
                    specification:'',
                    polish_roll:'',
                    full_thick:''
                },
                addFormRules: {
                    company_uuid: [
                        {required: true, message: '请选输入供应商', trigger: 'blur'}
                    ],
                    specification: [
                        {required: true, message: '请选输入规格名称', trigger: 'blur'}
                    ],
                    category: [
                        {required: true, message: '请选输入类别名称', trigger: 'blur'}
                    ],
                },
                goodsClassForm: {
                    classId: '',
                    numPrice: '',
                    numCost: '',//成本
                    setPriceRadio: 1,
                    setCostRadio: 1,//成本
               },
                specificationsClassificationFormRules: {
                    name: [
                        {required: true, message: "请输分类名称", trigger: "blur"},
                    ],
               },
                gridOptions: {
                    align:'left',
                    resizable: true,
                    showOverflow: true,
                    highlightHoverRow: true,
                    keepSource: true,
                    rowId: 'id',
                    size: 'medium',
					headerRowStyle: {height: '76px'},
                    ref: 'gridTree',
                    /*treeConfig: {expandAll: true, reserve: true},*/
                    editConfig: {
                        trigger: 'manual',
                        mode: 'row',
                        showStatus: true,
                   },
                    exportConfig: {},
                    checkboxConfig: {
                        /* 数不支持 range:true,*/

                        /*labelField:'id'*/
                   },
                    formData: {//搜索筛选
                        supplier:'',
                    },
                    filterData: {},
                    sortData: {"name":"asc"},
                    data: [],
                    columns: [
                        {type: 'checkbox',  title: '', width: 20},
                        {title: 'id', field:'id', width: 30},
                        {title: '分类名称', field:'name', treeNode: true, slots: {
                                header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.gridOptions.filterData, this.gridOptions.sortData, '/admin/everyDayPrice/searchHeardClassification', this.pickerDate, this.gridOptions.formData, null,this.findClassification,this.findClassification)
                            }
                        },
                        {title: '编辑', width: 78, slots: {default: 'operateTree'}},
                    ]
               },
                formData: {//搜索筛选
                    supplier:[],
               },
                pageType:'每天价格设置',
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 10000,
               },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                   },
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                       }
                   }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                       }
                   }, {
                        text: '一周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                       }
                   }]
               },
                tableColumn: [
                    {type: 'checkbox',  title: '', width: 20},
                    {
                        field:'id', width: 60, title: 'id', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPrice/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                        }
                    },
                    {
                        field:'state_stock', width: 60, title: '状态',
                        titleHelp: {message: '当前限价日期（包括时分秒） = 该规格限价日期（包括时分秒） ：手动 \n例如：当前限价日期 2023-5-1 9:30:12 如果该规格限价日期 2023-5-1 9:30:12 则为：手动\n如果有历史限价：自动 如果没有任何限价：无限价'},
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPrice/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                        }
                    },
                    {
                        field:'every_day_stock_specification', width: 150, title: '实际规格', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPrice/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                        }
                    },
                    {
                        field:'every_day_stock_category', width: 60, title: '类别', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPrice/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                        }
                    },
                    {
                        field:'full_thick', width: 60, title: '足厚', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPrice/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                        }
                    },
                    {
                        field:'polish_roll', width: 100, title: '打磨驳口', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPrice/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                        }
                    },
                    {
                        field:'company_name', width: 150, title: '供应商/批号',
                        titleHelp: {message: '盘点单据则显示批号'},
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPrice/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                        }
                    },{
                        field:'company_uuid', width: 150, title: '供应商uuid',visible:false,
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPrice/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                        }
                    },
                   {
                        field:'limited_price', width: 100, title: '最低限价',formatter: ({cellValue}) => {
                           return cellValue ? cellValue : 0
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPrice/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                       }
                   },
                   {
                        field:'cost_price', width: 100, title: '成本价格',formatter: ({cellValue}) => {
                           return cellValue ? cellValue : 0
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPrice/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                       }
                   },
                   {
                        field:'a_side', width: 65, title: 'A边', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPrice/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                       }
                   },
                   {
                        field:'b_side', width: 65, title: 'B边', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPrice/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                       }
                   },
                   {
                        field:'thickness', width: 65, title: '厚度', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPrice/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                       }
                   },
                   {
                        field:'length_extent', width: 65, title: '长度', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPrice/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                       }
                   },
                   {
                        field:'single_weight', width: 100, title: '支重(理论)',visible:false, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPrice/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                       }
                   }, {
                        field:'category_class', width: 240, title: '所属分类', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPrice/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                       }
                   },{
                        field:'limited_price_date', width: 180, title: '限价日期', visible:true, formatter: ({cellValue}) => {
                            return this.$_formatDate(cellValue ? cellValue : this.pickerDate,  'yyyy-MM-dd HH:mm:ss')
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPrice/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                        }
                    },
                ],
                tableDataMain: [],
                filterData: {},
                sortData: {
                    "a_side":"desc",
                    "b_side":"desc",
                    "thickness":"desc",
                    "length_extent":"desc",
                },
                pickerDate: '',
                activeName: 'first',
                checkedGoodsIds: [],
                batchList: [],
                checkedGoods: [],
                checkedGridClassIds: [],
                timeHour: '',
                timeHourList:[]
           }
       },
        computed: {},

        methods: {
            cellClassName({column}) {
                if(column.property == 'limited_price' || column.property == 'cost_price'){
                    return 'red-row';
                } else {
                    return '';
                }
            },
            addBatchNumberEvent(){
                this.$axios({
                    method:'post',
                    url:'/admin/everyDayPrice/addBatchNumberEvent',
                    data: {
                        addBatchNumber:this.addBatchNumber
                    }
                }).then((response) =>{         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200 && response.data.state == 'ok'){
                        this.searchEveryList();
                        //判断能成功存进去的规格，直接插入前端表格第一行
                        //编列对象
                        //console.log(response.data.specificationList);

                        /*response.data.specificationList.forEach(item => {
                            this.$refs.xGrid.insert({
                                specification: item.specification_name,
                                category: item.category_name
                            })
                        });
                        this.showAddStockDayBalance = false;
                        this.addBatchNumber = '';*/
                    }else{
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                        return
                    }
                }).catch((error) =>{
                    console.log(error)
                });
            },
            addGood(){
                this.$refs.addFrom.validate((valid) => {
                    if (valid) {
                        this.$axios({
                            method: 'post',
                            url: '/admin/everyDayPrice/addGood',
                            data: {
                                formData: this.addFrom,
                            },
                        }).then((response) => {          //这里使用了ES6的语法
                            //console.log(response)       //请求成功返回的数据
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: '操作成功',
                                    type: 'success'
                                });
                                this.searchEveryList();
                            } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                                });
                                return
                            }
                        }).catch((error) => {
                            console.log(error)
                        });
                    }
                });
            },
            //设置商品分类、商品同步、商品限价
            saveGoodsClass(typeAction) {
                let url = ''
                if (typeAction == 'setClass') {
                    url = '/admin/everyDayPrice/saveGoodsClass'
               } else if (typeAction == 'setPrice') {
                    url = '/admin/everyDayPrice/setPriceGoods'
               }
                //作废，改用数据库控制，不设置最新价格，默认读取上一次最近的价格
                /* else if (typeAction == 'synGoodsPrice') {
                    url = '/admin/everyDayPrice/synGoodsPrice'
               }*/
                this.$axios({
                    method: 'post',
                    url: url,
                    data: {
                        date: this.pickerDate,
                        checkedGoods: this.checkedGoods,
                        checkedGoodsIds: this.checkedGoodsIds,
                        classId: this.goodsClassForm.classId,
                        numPrice: this.goodsClassForm.numPrice,
                        numCost: this.goodsClassForm.numCost,
                        setPriceRadio: this.goodsClassForm.setPriceRadio,
                        setCostRadio: this.goodsClassForm.setCostRadio,
                   },
               }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '操作成功',
                            type: 'success'
                       });
                        this.goodsClassDialogFormVisible = false;
                        this.setPriceDialogFormVisible = false;
                        this.searchEveryList();
                        this.getEveryDayTime();
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            showGoodsClassDialogFormEvent() {
                this.goodsClassDialogFormVisible = true;
                this.goodsClassCheckedEvent();
                //console.log(this.getSelectionEvent('xGrid'));
           },
            goodsClassCheckedEvent() {
                this.checkedGoodsIds = this.getSelectionEvent('xGrid');
                this.checkedGoods = this.$refs['xGrid'].getCheckboxRecords();
                this.getSelectionBatchListEvent('xGrid');
                //console.log('batchList',this.batchList)
                //console.log('checkedGoodsIds',this.checkedGoodsIds)
           },
            addClass() {
                this.dialogFormVisible = true;
                this.showEdit = false;
                this.specificationsClassificationForm.father_id = '';
                this.specificationsClassificationForm.name = '';
                this.specificationsClassificationForm.id = '';
                this.specificationsClassificationForm.is_able_change = true;
           },
            //设置同步限价，更新最新一次价格 作废，改用数据库控制，不设置最新价格，默认读取上一次最近的价格
           /* synGoodsPrice() {
                this.$XModal.confirm('您确定要同步：最近一次限价?').then(type => {
                    if (type === 'confirm') {
                        this.goodsClassCheckedEvent();
                        this.saveGoodsClass('synGoodsPrice');
                   }
               });
           },*/
            saveSpecificationsClassification(formName) {
                let url = '';
                if (this.showEdit) {
                    url = '/admin/everyDayPrice/updateClass';
               } else {
                    url = '/admin/everyDayPrice/saveClass';
               }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$axios({
                            method: 'post',
                            url: url,
                            data: this.specificationsClassificationForm,
                       }).then((response) => {          //这里使用了ES6的语法
                            //console.log(response)       //请求成功返回的数据
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: '保存成功',
                                    type: 'success'
                               });
                                this.dialogFormVisible = false;
                                this.findClassification();
                           } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                               });
                                return
                           }
                       }).catch((error) => {
                            console.log(error)
                       });
                   } else {
                        //console.log('error submit!!');
                        return false;
                   }
               });
           },
            cellCheckboxRowEvent({row, rowIndex, column}) {
                //console.log(row, rowIndex,column)
                //console.log(row)
                if (column.property == 'name' || column.property == 'id') {
                    if (this.$refs.gridClass.isCheckedByCheckboxRow(row)) {
                        this.$refs.gridClass.setCheckboxRow([this.gridOptions.data[rowIndex]], false)
                    } else {
                        this.$refs.gridClass.setCheckboxRow([this.gridOptions.data[rowIndex]], true)
                    }
                }
                this.searchEveryList()
                this.$refs.gridClass.clearSelected();
            },
            // eslint-disable-next-line no-unused-vars
            cellCheckboxRowEventGrid({row, rowIndex, column}) {
                //console.log(row, rowIndex,column)
                //console.log(column)
                if (column.type != 'checkbox') {
                    if (this.$refs.xGrid.isCheckedByCheckboxRow(row)) {
                        this.$refs.xGrid.setCheckboxRow([this.tableDataMain[rowIndex]], false)
                    } else {
                        this.$refs.xGrid.setCheckboxRow([this.tableDataMain[rowIndex]], true)
                    }
                    this.$refs.xGrid.clearSelected();
                }
                this.goodsClassCheckedEvent()
            },
            findClassification() {
                this.$axios({
                    method: 'POST',
                    url: '/admin/everyDayPrice/findClassification',
                    data:{
                        sortData:this.gridOptions.sortData,
                        filterData:this.gridOptions.filterData,
                        formData:this.gridOptions.formData,
                    }
               }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.gridOptions.data = response.data
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            getMaxCreateDate(){
                this.$axios({
                    method: 'POST',
                    url: '/admin/everyDayPrice/getMaxCreateDate',
                }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.pickerDate = response.data.picker_date
                        this.timeHour = XEUtils.toDateString(response.data.picker_date, 'HH:mm:ss')
                    }
                    this.getEveryDayTime();
                }).catch((error) => {
                    console.log(error)
                });
            },
            //修改多时段自动关联修改 日期
            changePickerDate(){
                this.pickerDate = XEUtils.toDateString(this.pickerDate, 'yyyy-MM-dd') + ' ' + this.timeHour
                this.searchEveryList();
            },
            //修改多时段自动关联修改 日期
            changeTimeHour(){
                this.getEveryDayTime();
                this.timeHour = XEUtils.toDateString(this.pickerDate, 'HH:mm:ss')
                this.searchEveryList();
            },

            getEveryDayTime(){
                this.$axios({
                    method: 'POST',
                    url: '/admin/everyDayPrice/getEveryDayTime',
                    params:{
                        pickerDate:this.pickerDate
                    }
                }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.timeHourList = response.data.timeHourList
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.searchEveryList();
           },
            editRowEvent(row) {
                this.showEdit = true;
                this.dialogFormVisible = true;
                this.specificationsClassificationForm = row;
           },
            removeRowEvent(row) {
                this.$XModal.confirm('您确定要删除 ' + row.name + ' 分类（子分类 将变成 父类）?').then(type => {
                    //const $table = this.$refs.xTable
                    if (type === 'confirm') {
                        //传送删除动作
                        this.$axios({
                            method: 'post',
                            url: '/admin/everyDayPrice/deleteClass',
                            params: {
                                id: row.id
                           }
                       }).then((response) => {          //这里使用了ES6的语法
                            //console.log(response)       //请求成功返回的数据
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: '删除成功',
                                    type: 'success'
                               });
                                this.findClassification();
                           } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                               });
                                return
                           }
                       }).catch((error) => {
                            console.log(error)
                       });
                   }
               })
           },
            getSelectionEvent(ref) {
                const $grid = this.$refs[ref];
                const selectRecords = $grid.getCheckboxRecords();
                let ids = selectRecords.map((item) => {
                    //console.log(item)
                    return item.id
                });
                return ids;
           },
            //获取批号,用于删除的批号，设置stock 批号不是每天限价 is_every_day = false
            getSelectionBatchListEvent(ref) {
                this.batchList = [];
                const $grid = this.$refs[ref];
                const selectRecords = $grid.getCheckboxRecords();
                //console.log(selectRecords)
                //let ids = selectRecords.map((item) => {
                selectRecords.map((item) => {
                    //console.log(item.company_name,item.company_name.startsWith("PH"));
                    if(item.company_name.startsWith("PH")){
                        this.batchList.push(item.company_name)
                        //console.log(item,item.company_name);
                        //return item.company_name
                    }
                    //console.log(item)
                });
                //return ids;
            },
            deleteEveryDay(){
                this.goodsClassCheckedEvent();
                this.$XModal.confirm('您确定要删除该数据?' + this.checkedGoodsIds).then(type => {
                    //const $table = this.$refs.xTable
                    if (type === 'confirm') {
                        this.$axios({
                            method: 'post',
                            url: '/admin/everyDayPrice/deleteGoods',
                            data: {
                                date: this.pickerDate,
                                checkedGoodsIds: this.checkedGoodsIds,
                                batchList: this.batchList,
                            },
                        }).then((response) => {          //这里使用了ES6的语法
                            //console.log(response)       //请求成功返回的数据
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: '操作成功',
                                    type: 'success'
                                });
                                this.searchEveryList();
                            } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                                });
                                return
                            }
                        }).catch((error) => {
                            console.log(error)
                        });
                    }
                });
            },
            searchEveryList() {
                delete this.filterData.supplier_name
                delete this.filterData.state
                delete this.filterData.bill_id_type
                this.checkedGridClassIds = this.getSelectionEvent('gridClass');
                this.tableDataMain = [];
                this.$axios({
                    method: 'post',
                    url: '/admin/everyDayPrice/searchEveryList',
                    data: {
                        checkedGridClassIds: this.checkedGridClassIds,
                        pickerDate: this.pickerDate,
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        sortData:this.sortData,
                        formData:this.formData,
                        filterData:this.filterData,
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            duration: 500,
                            message: '查询成功',
                            type: 'success'
                        });
                        this.tableDataMain = response.data.page.list;
                        this.tablePage.total = response.data.page.totalRow;
                        this.tablePage.currentPage = response.data.page.pageNumber;
                        this.tablePage.pageSize = response.data.page.pageSize;
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                       return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            importMethod({file}) {
                const loading = this.$loading({
                    lock: true,
                    text: '导入中，请喝杯水，耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                const formBody = new FormData()
                formBody.append('file', file)
                this.$axios({
                    method: 'post',
                    url: '/admin/everyDayPrice/importExcel',
                    data: formBody,
                }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.tableDataImport = response.data.list
                        this.isShowDialog = response.data.isOkSave
                        this.uuid = response.data.uuid
                        this.dialogImprotVisible = true;
                    } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                        return
                    }
                }).catch((error) => {
                    loading.close();
                    this.$message({
                        showClose: true,
                        message: '网络超时，请重试！',
                        type: 'error'
                    });
                    console.log(error)
                });
            },
            saveImport() {
                const loading = this.$loading({
                    lock: true,
                    text: '数据录入中，耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$axios({
                    method: 'post',
                    url: '/admin/everyDayPrice/saveImportExcel',
                    params: {
                        uuid: this.uuid
                    }
                }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '导入成功',
                            type: 'success'
                        });
                        this.dialogImprotVisible = false
                    } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                        return
                    }
                    this.searchEveryList();
                }).catch((error) => {
                    loading.close();
                    this.$message({
                        showClose: true,
                        message: '网络超时，请重试！',
                        type: 'error'
                    });
                    console.log(error)
                });
            },
       },
        created() {
            this.$nextTick(() => {
                this.getMaxCreateDate();
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
                this.findClassification();
           })

       }
   };


</script>

<style scoped>
/*  暂时没必要用动画，动画只是一个测试  .fade-enter-active, .fade-leave-active {
        transition: 1s;
    }
    .fade-enter, .fade-leave-to{
        opacity: 0;
        transform: translateX(-25rem);
    }*/
    .grid-show-button{
        position: fixed;
        left: 0%;
        top: 50%;
        height: 60px;
        user-select: none;
        padding: 0 !important;
    }
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
   }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }

    .el-form-item__content .el-input {
        width: 130px;
   }

    /*    .el-form-item__content .el-input{
            width: 80% !important;
       }*/
    /deep/ .el-form-item__error {
        position: initial !important;
   }

    .el-tag + .el-tag {
        margin-left: 10px;
        margin-top: 10px;
   }

    /deep/ .el-input--suffix .el-input__inner{
        padding-right: initial!important;
   }

    /deep/ input[popperclass="myAutocomplete"]{
        padding:3px!important;
   }
    /deep/ .el-input__suffix {
        right: 0px!important;
   }

    /deep/ .vxe-cell-help-icon.vxe-icon--question{
        display: -webkit-box !important;
        position: absolute !important;
    }
</style>
